// 3rd
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { EntityType } from '@/types/entity';
import type { Collection } from '../types/collection';
import { castEntityCollectionDtoToCollection, ZodEntityCollectionDto } from './dtos/collection';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { COLLECTIONS_QUERY_KEYS } from '../config/react-query-key-factory';
import { castEntityTypeToEntityTypeDto, EntityTypesDto } from '@/types/entity/dtos';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  id: z.string(),
  entityType: z.enum(EntityTypesDto),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodGetCollectionRequestPayloadDto };
export type { RequestPayloadDto as GetCollectionRequestPayloadDto };

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  entityCollection: ZodEntityCollectionDto,
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodGetCollectionResponseDto };
export type { ResponseDto as GetCollectionResponseDto };

// #######
// Request
// #######

export const getCollection = async (
  entityType: EntityType,
  collectionId: string
): Promise<Collection> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      entityType: castEntityTypeToEntityTypeDto(entityType),
      id: collectionId,
    });
    const res = await apiClient.post(`/Collection/GetCollection`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return castEntityCollectionDtoToCollection(parsedRes.entityCollection);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCollection = {
  collectionId?: string;
  entityType?: EntityType;
  enabled?: boolean;
};

export const useCollection = ({ collectionId, entityType, enabled = true }: UseCollection = {}) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: COLLECTIONS_QUERY_KEYS.collection(entityType!, collectionId!),
    queryFn: async () => await getCollection(entityType!, collectionId!),
    enabled: enabled && !!entityType && !!collectionId,
  });

  return {
    collection: data,
    isFetchingCollection: isLoading || isFetching,
    didFetchCollectionErrored: isLoadingError,
  };
};
