// 3rd
import { z } from 'zod';

// App - Types
import { castEntityTypeDtoToEntityType, EntityTypesDto } from '@/types/entity/dtos';
import type { Collection } from '../../types/collection';
import { castEntityTypeToKnowledgeSource } from '@/types/entity';

export const ZodEntityCollectionDto = z.object({
  applications: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
      })
    )
    .optional(),
  collectionId: z.string(),
  entityType: z.enum(EntityTypesDto),
  id: z.string(),
  knowledgeSourceConfigurationId: z.string(),
  title: z.string(),
});

export type EntityCollectionDto = z.infer<typeof ZodEntityCollectionDto>;

export const castEntityCollectionDtoToCollection = (dto: EntityCollectionDto): Collection => {
  const entityType = castEntityTypeDtoToEntityType(dto.entityType);
  const source = castEntityTypeToKnowledgeSource(entityType);

  return {
    id: dto.id,
    collectionId: dto.collectionId,
    source,
    knowledgeSourceConfigurationId: dto.knowledgeSourceConfigurationId,
    entityType,
    name: dto.title,
    applications: dto.applications?.map((app) => ({
      id: app.id,
      name: app.name,
    })),
  };
};
