// 3rd
import { z } from 'zod';

// App - Types
import { castEntityTypeDtoToEntityType, EntityTypesDto } from '@/types/entity/dtos';
import { ItemCategoriesDto, castItemCategoryDtoToItemCategory } from '@/types/unions/dtos';
import type { ItemCategoryDto } from '@/types/unions/dtos';
import {
  KnowledgeItemPrioritiesDto,
  castKnowledgeItemPriorityDtoToKnowledgeItemPriority,
} from '@/types/knowledge-item/dtos';
import type { KnowledgeItemPriorityDto } from '@/types/knowledge-item/dtos';
import { castEntityTypeToEntityKind, castEntityTypeToKnowledgeSource } from '@/types/entity';
import type { CollectionStats } from '../../types/collection';

// App - Other
import { normalizeDatesToISOString } from '@/utils/format';

export const ZodEntityCollectionStatsDto = z.object({
  applications: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
      })
    )
    .optional(),
  collectionId: z.string(),
  collectionTitle: z.string(),
  documentPriorityToCount: z
    .record(z.enum(KnowledgeItemPrioritiesDto), z.number().optional())
    .catch((e) => {
      console.error('DocumentPriorityToCount parsing errored! see:', e);

      return {};
    }),
  entityType: z.enum(EntityTypesDto),
  documentTypeToCount: z.record(z.enum(ItemCategoriesDto), z.number().optional()).catch((e) => {
    console.error('DocumentTypeToCount parsing errored! see:', e);

    return {};
  }),
  id: z.string(),
  latestActivity: z.string().optional(),
  prioritizedDocumentCount: z.number(),
  totalDocumentCount: z.number(),
});

export type EntityCollectionStatsDto = z.infer<typeof ZodEntityCollectionStatsDto>;

export const castEntityCollectionStatsDtoToCollectionStats = (
  dto: EntityCollectionStatsDto
): CollectionStats => {
  const entityType = castEntityTypeDtoToEntityType(dto.entityType);
  const source = castEntityTypeToKnowledgeSource(entityType);
  const kind = castEntityTypeToEntityKind(entityType);

  return {
    applications: dto.applications?.map((app) => ({
      id: app.id,
      name: app.name,
    })),
    collectionId: dto.collectionId,
    name: dto.collectionTitle,
    id: dto.id,
    source,
    entityType,
    kind,
    latestActivity: dto.latestActivity ? normalizeDatesToISOString(dto.latestActivity) : undefined,
    totalItemsCount: dto.totalDocumentCount,
    prioritizedItemsCount: dto.prioritizedDocumentCount,
    prioritizedItemsPrioritiesToCount: Object.entries(dto.documentPriorityToCount).reduce(
      (acc, entry) => {
        const priorityDto = entry[0] as KnowledgeItemPriorityDto;
        const priority = castKnowledgeItemPriorityDtoToKnowledgeItemPriority(priorityDto);
        const totalCount = entry[1] as number;

        if (totalCount) acc[priority] = totalCount;

        return acc;
      },
      {} as CollectionStats['prioritizedItemsPrioritiesToCount']
    ),
    prioritizedItemsClassificationsToCount: Object.entries(dto.documentTypeToCount).reduce(
      (acc, entry) => {
        const categoryDto = entry[0] as ItemCategoryDto;
        const category = castItemCategoryDtoToItemCategory(categoryDto);
        const totalCount = entry[1] as number;

        if (totalCount) acc[category] = totalCount;

        return acc;
      },
      {} as CollectionStats['prioritizedItemsClassificationsToCount']
    ),
  };
};
