// 3rd
import type { DateTime } from 'luxon';

// App - Types
import type { KnowledgeSource } from '@/types/integration';
import type { EntityKind, EntityType } from '@/types/entity';
import type { KnowledgeItemPriority, KnowledgeItemCategory } from '@/types/knowledge-item';

export type Collection = {
  id: string;
  collectionId: string;
  knowledgeSourceConfigurationId: string;
  name: string;
  source: KnowledgeSource;
  entityType: EntityType;
  applications?: CollectionApplication[];
};

export type CollectionStats = {
  id: string;
  collectionId: string;
  source: KnowledgeSource;
  entityType: EntityType;
  kind: EntityKind;
  name: string;
  latestActivity?: DateTime;
  totalItemsCount: number;
  prioritizedItemsCount: number;
  prioritizedItemsPrioritiesToCount: {
    [key in KnowledgeItemPriority]?: number;
  };
  prioritizedItemsClassificationsToCount: {
    [key in KnowledgeItemCategory]?: number;
  };
  applications?: CollectionApplication[];
};

export type CollectionApplication = {
  id: string;
  name: string;
};

export const getUniqueCollectionId = (collectionStats: { entityType: EntityType; id: string }) => {
  return `${collectionStats.entityType}-${collectionStats.id}`;
};
