// React & Next
import { useMemo } from 'react';

// 3rd
import { createFilter } from 'react-select';
import { Skeleton, forwardRef, Flex } from '@chakra-ui/react';
import type { ChakraComponent, StyleProps } from '@chakra-ui/react';

// App - Types
import { getUniqueCollectionId } from '../../types/collection';
import type { CollectionStats } from '../../types/collection';

// App - Other
import Locale from '@/locale/en.json';
import { Select } from '@/components/molecules/form';
import { KnowledgeSourceIcon } from '@/components/translators/integration';
import { translateEntityTypeToScrappedEntity } from '@/components/translators/entity';

const locale = Locale.features.collections['collections-select-collection'];

type CollectionsSelectCollectionComponent = ChakraComponent<
  'div',
  CollectionsSelectCollectionProps
> & {
  Loading: typeof Loading;
};

type CollectionsSelectCollectionProps = StyleProps & {
  collections: CollectionStats[];
  includeDefaultOption?: boolean | string;
  placeholder?: string;
  selected?: Array<CollectionStats>;
  onChange: (collections?: Array<CollectionStats>) => void;
  onBlur?: () => void;
  isDisabled?: boolean;
  controlShouldRenderValue?: boolean;
};

export const CollectionsSelectCollection = forwardRef(
  (
    {
      collections,
      includeDefaultOption,
      placeholder,
      selected,
      onChange,
      onBlur,
      isDisabled,
      controlShouldRenderValue = true,
      ...props
    }: CollectionsSelectCollectionProps,
    ref
  ) => {
    const defaultOption = useMemo(
      () => ({
        value: '',
        label:
          typeof includeDefaultOption === 'string'
            ? includeDefaultOption
            : locale['Select collection'],
      }),
      [includeDefaultOption]
    );

    const options = useMemo(() => {
      const collectionOptions = collections
        .map((collection) => {
          return {
            value: getUniqueCollectionId(collection),
            label: getUniqueCollectionId(collection),
          };
        })
        .sort((a, b) =>
          a.label.localeCompare(b.label, undefined, {
            numeric: true,
          })
        );

      if (!!includeDefaultOption && collectionOptions.length > 1)
        return [defaultOption, ...collectionOptions];

      return collectionOptions;
    }, [collections, defaultOption, includeDefaultOption]);

    const selectedOptions = useMemo(() => {
      return (selected || []).map((collection) => {
        // const sourceIcon = createElement(translateKnowledgeSourceToIcon(collection.source), {
        //   size: 'xs',
        //   'aria-label': locale['Collection’s source'],
        // });

        return {
          value: getUniqueCollectionId(collection),
          label: getUniqueCollectionId(collection),
        };
      });
    }, [selected]);

    return (
      <Select
        useBasicStyles
        variant="outline"
        size="sm"
        isMulti
        isDisabled={isDisabled}
        options={options}
        value={selectedOptions}
        selectedOptionColor="surface.brand.primary"
        placeholder={placeholder}
        onBlur={onBlur}
        controlShouldRenderValue={controlShouldRenderValue}
        onChange={(options) => {
          if (!options || !options.length) {
            return onChange([]);
          }

          const selectedCollections = options.flatMap((option) => {
            const collection = collections.find((c) => getUniqueCollectionId(c) === option.value);

            return collection ? [collection] : [];
          });

          return onChange(selectedCollections);
        }}
        ref={ref}
        isSearchable={true}
        closeMenuOnSelect={true}
        hideSelectedOptions={true}
        isClearable={false}
        filterOption={createFilter({
          stringify: (option) => {
            const collection = collections.find((c) => getUniqueCollectionId(c) === option.value);

            return collection
              ? `${collection.source} ${collection.entityType} ${collection.name}`
              : '';
          },
        })}
        formatOptionLabel={(option) => {
          const collection = collections.find((c) => `${c.entityType}-${c.id}` === option.value);

          if (!collection) return null;

          return (
            <Flex alignItems="center" gap="sm">
              <KnowledgeSourceIcon source={collection.source} size="2xs" />

              {['github_pull_request', 'github_issue'].includes(collection.entityType)
                ? `(${translateEntityTypeToScrappedEntity(collection.entityType, { count: 0 })}) `
                : null}

              {collection.name}
            </Flex>
          );
        }}
        chakraStyles={{
          container: (styles) => ({ ...styles, ...props }),
          menuList: (styles) => ({ ...styles, ...props }),
        }}
      />
    );
  }
) as CollectionsSelectCollectionComponent;

type LoadingProps = StyleProps;

const Loading = ({ ...props }: LoadingProps) => {
  return <Skeleton {...props} height="26px" />;
};

CollectionsSelectCollection.Loading = Loading;
